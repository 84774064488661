/* src/styles/Radar.css */

.radar {
  position: absolute; /* Change to absolute positioning */
  top: 0; /* Position at the top */
  right: 0; /* Position at the right */
  width: 100px; /* Adjusted size */
  height: 100px; /* Adjusted size */
  z-index: 1000; /* Ensure it is on top of other elements */
}

.radar-circle {
  position: relative;
  width: 100%;
  height: 100%;
  border: 2px solid #00ff00;
  border-radius: 50%;
}

.radar-player,
.radar-other-player {
  position: absolute;
  width: 5px; /* Adjusted size */
  height: 5px; /* Adjusted size */
  border-radius: 50%;
}

.radar-player {
  background-color: #00ff00;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
}

.radar-player-name {
  position: absolute;
  color: #00ff00;
  text-align: center;
  width: 100%;
  font-size: 10px; /* Adjusted size */
  transform: translate(-50%, 0);
}

.radar-other-player-wrapper {
  position: absolute;
}

.radar-other-player {
  background-color: #ff0000;
}

.radar-other-player-name {
  position: absolute;
  color: #ff0000;
  text-align: center;
  width: 100%;
  font-size: 10px; /* Adjusted size */
  transform: translate(-50%, 0);
}
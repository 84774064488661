/* Global Styles */
body, html, #root {
  margin: 0;
  padding: 0;
  height: 100%;
  width: 100%;
  font-family: 'Orbitron', sans-serif;
  overflow: hidden;
}

/* App Container */
.App {
  text-align: center;
  color: #E0E1FF; /* Light blue-white for text */
  min-height: 100vh;
  position: relative;
}

/* Navigation Bar */
nav {
  background-color: rgba(8, 24, 58, 0.7); /* Dark blue with transparency */
  padding: 1rem 0;
  position: absolute;
  top: 0;
  width: 100%;
  z-index: 10;
}

nav ul {
  list-style-type: none;
  padding: 0;
  margin: 0;
}

nav ul li {
  display: inline;
  margin: 0 15px;
}

nav ul li a {
  text-decoration: none;
  color: #4CC9F0; /* Bright blue for links */
  font-weight: bold;
  transition: color 0.3s ease;
}

nav ul li a:hover {
  color: #F72585; /* Bright pink on hover */
}

/* Server Status */
.server_status {
  color: #205083; /* Dark blue */
  position: absolute;
  bottom: 2.1%;
  background-color: rgba(255, 255, 205, 0.5);
  border-radius: 150px;
  padding: 0.5rem 1rem;
  height: 5%;
  justify-content: center;
  align-items: center;
  display: flex;
}

.server_status p {
  align-self: center;
  display: flex;
  justify-content: center;
  margin: 0;
}

/* Add media queries for responsive design */
@media (max-width: 600px) {
  .App {
    display: flex;
    flex-direction: column;
    margin: 0;
    padding: 0;
    height: 100%;
    font-family: 'Arial', sans-serif;
    min-height: 100vh;
  }

  .server_status, .connected-users, .leaderboard {
    width: 100%;
    font-size: 12px;
  }

  .crosshair {
    width: 20px;
    height: 20px;
  }

  .health-bar {
    width: 150px;
  }
}

/* Media query for landscape mode */
@media (orientation: landscape) {
  body, html, #root {
    height: 100vh;
    width: 100vw;
    overflow: hidden;
  }

  .App {
    min-height: 100vh;
    min-width: 100vw;
  }

  nav {
    padding: 1rem;
  }

  .server_status {
    bottom: 1%;
    width: auto;
    height: auto;
    padding: 0.5rem 1rem;
  }
}
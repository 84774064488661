.dashboard {
  position: fixed;
  bottom: -100%; /* Hide off-screen initially */
  width: 100%; /* Increase the width */
  height: 100%; /* Increase the height */
  left: 0;
  background-color: rgba(44, 62, 80, 0.8); /* Set background color with transparency */
  color: white;
  padding: 5px;
  box-shadow: 0 -2px 5px rgba(0, 0, 0, 0.5);
  transition: bottom 0.3s ease;
  z-index: 1000; /* Ensure it's above other elements */
  display: flex;
  flex-direction: column;
  overflow-y: auto; /* Enable vertical scrolling */
}

.dashboard.open {
  bottom: 0; /* Slide in when open */
}

.close-btn {
  background: none;
  border: none;
  color: white;
  font-size: 1.5em;
  position: absolute;
  top: 10px;
  right: 10px;
  cursor: pointer;
}

.dashboard-content {
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  padding: 10px;
  width: 100%;
}

.dashboard-content-top {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.dashboard-content-bottom {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.button-container {
  width: 100%;
  display: flex;
  justify-content: center; /* Center horizontally */
  margin-top: 10px;
}

.cool-green-button {
  background-color: #4CAF50; /* Green background */
  border: none; /* Remove border */
  color: white; /* White text */
  padding: 15px 32px; /* Some padding */
  text-align: center; /* Centered text */
  text-decoration: none; /* Remove underline */
  display: inline-block; /* Make the button inline-block */
  font-size: 16px; /* Increase font size */
  margin: 4px 2px; /* Some margin */
  cursor: pointer; /* Pointer/hand icon */
  border-radius: 12px; /* Rounded corners */
  transition: background-color 0.3s ease; /* Smooth transition for background color */
}

.cool-green-button:hover {
  background-color: #45a049; /* Darker green on hover */
}

.connected-users {
  background-color: rgba(255, 255, 255, 0.1);
  border-radius: 5px;
  justify-content: center;
  align-items: center;
  padding: 10px;
  width: 100%;
  display: flex;
  text-align: center;
}

.leaderboard {
  margin-top: 20px;
  width: 80%;
  justify-content: center;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.leaderboard h2 {
  margin-bottom: 10px;
  text-align: center;
}

.leaderboard ol {
  list-style-type: decimal;
  padding-left: 20px;
}

.leaderboard li {
  margin-bottom: 5px;
}

.install-button {
  padding: 10px 20px;
  font-size: 16px;
  color: white;
  background-color: #007bff;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  margin-top: 20px;
}

.install-button:hover {
  background-color: #0056b3;
}
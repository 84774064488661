/* Home Page */
html, body {
  height: 100%;
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

.home {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100vh;
  position: relative;
  z-index: 1;
  padding: env(safe-area-inset-top) env(safe-area-inset-right) env(safe-area-inset-bottom) env(safe-area-inset-left);
}

.intro-visual {
  position: absolute;
  height: calc(100% - env(safe-area-inset-top) - env(safe-area-inset-bottom));
  width: calc(100% - env(safe-area-inset-left) - env(safe-area-inset-right));
  background-color: #0b132b; /* Keeping the original background color */
  top: env(safe-area-inset-top);
  left: env(safe-area-inset-left);
  z-index: -10;
}

.content {
  background-color: rgba(8, 24, 58, 0.8); /* Dark blue with high transparency */
  padding: 2rem;
  border-radius: 15px;
  max-width: 600px;
  margin: 0 auto;
}

h1 {
  font-size: 2.5rem;
  margin-bottom: 1rem;
  color: #4CC9F0; /* Bright blue for headings */
}

p {
  font-size: 1.2rem;
  margin-bottom: 2rem;
  line-height: 1.6;
}

.feature-links {
  display: flex;
  justify-content: center;
  gap: 1rem;
}

.button {
  background-color: #3A0CA3; /* Deep purple for buttons */
  color: #ffffff;
  padding: 0.8rem 1.5rem;
  border-radius: 25px;
  text-decoration: none;
  font-weight: bold;
  transition: background-color 0.3s ease, transform 0.2s ease;
  border: 2px solid #4CC9F0; /* Bright blue border */
}

.button:hover {
  background-color: #4361EE; /* Lighter purple on hover */
  transform: translateY(-2px);
}

/* Canvas */
canvas {
  width: 100% !important;
  height: 100% !important;
}

/* Crosshair */
.crosshair {
  position: fixed; /* Ensures it's fixed in the center of the screen */
  top: 50%;
  left: 50%;
  width: 20px;
  height: 20px;
  border: 2px solid white;
  border-radius: 50%;
  transform: translate(-50%, -50%);
  pointer-events: none; /* Allow clicks to pass through */
  z-index: 1000; /* Ensures it is on top of other elements */
}

/* PvP Mode Button */
.pvpMode {
  position: absolute;
  top: 10rem;
  left: 1rem;
  background-color: #3A0CA3;
  color: #ffffff;
  padding: 0.8rem 1.5rem;
  border-radius: 25px;
  font-weight: bold;
  transition: background-color 0.3s ease, transform 0.2s ease;
  border: 2px solid #4CC9F0;
  cursor: pointer;
}

.pvpMode:hover {
  background-color: #4361EE;
  transform: translateY(-2px);
}

/* Hit Effect */
.hit-effect {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(255, 0, 0, 0.5); /* Red semi-transparent overlay */
  pointer-events: none;
  z-index: 1000;
}

/* Ensure the game elements are responsive */
.bottom-left {
  position: absolute;
  bottom: 30%;
  left: 60px;
}

.upper-right {
  position: absolute;
  top: 0;
  right: 2%;
}

.bottom-right {
  position: absolute;
  bottom: 50%;
  right: 60px;
}

.health-bar {
  position: absolute;
  top: 10%;
  left: 50%;
  transform: translateX(-50%);
  width: 50%;
  height: 20px;
  background-color: #3A0CA3;
  border-radius: 10px;
  overflow: hidden;
}

.health-bar-inner {
  height: 100%;
  background-color: #4CC9F0;
  border-radius: 10px;
  transition: width 0.3s ease;
}

/* Button to open the dashboard */
.open-dashboard-btn {
  position: absolute;
  top: 10px;
  left: 20px;
  background-color: #3498db;
  color: white;
  border: none;
  padding: 10px 20px;
  border-radius: 5px;
  cursor: pointer;
  z-index: 1000;
}

.open-dashboard-btn:hover {
  background-color: #2980b9;
}

.shoot-projectile-btn-left {
  position: absolute;
  top: 10rem;
  left: 1rem;
  background-color: #3A0CA3;
  color: #ffffff;
  padding: 0.8rem 1.5rem;
  border-radius: 25px;
  font-weight: bold;
  transition: background-color 0.3s ease, transform 0.2s ease;
  border: 2px solid #4CC9F0;
  cursor: pointer;
}

.pvpMode:hover {
  background-color: #4361EE;
  transform: translateY(-2px);
}

.bottom-left {
  position: absolute;
  bottom: 30%;
  left: 20px;
}

.upper-right {
  position: absolute;
  top: 0;
  right: 2%;
}

.bottom-right {
  position: absolute;
  bottom: 30%;
  right: 20px;
}

.shoot-btn-right {
  position: fixed;
  top: 50%; /* Stick to the bottom with a 20px margin */
  right: 130px; /* Adjust the right position as needed */
  width: 50px; /* Set a fixed width */
  height: 50px; /* Set a fixed height */
  background-color: #ff0000;
  color: #ffffff;
  padding: 10px 20px;
  border: none;
  border-radius: 50%; /* Make it a circle */
  cursor: pointer;
  z-index: 1000;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 16px; /* Adjust font size as needed */
  border: 2px solid #ffff00; /* Add yellow border */
}

.shoot-btn-left {
  position: fixed;
  top: 50%; /* Stick to the bottom with a 20px margin */
  left: 130px; /* Adjust the right position as needed */
  width: 50px; /* Set a fixed width */
  height: 50px; /* Set a fixed height */
  background-color: #ff0000;
  color: #ffffff;
  padding: 10px 20px;
  border: none;
  border-radius: 50%; /* Make it a circle */
  cursor: pointer;
  z-index: 1000;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 16px; /* Adjust font size as needed */
  border: 2px solid #ffff00; /* Add yellow border */
}

.shoot-projectile-btn-right {
  position: absolute;
  top: 30%;
  left: calc(20px + env(safe-area-inset-left));
  padding: 10px 20px;
  background-color: red;
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  z-index: 1000;
}

.shoot-projectile-btn:hover {
  background-color: darkred;
}

/* General mobile styles for horizontal orientation */
@media (width: 1024px) and (orientation: landscape) {
  .home {
    flex-direction: row;
    align-items: flex-start;
  }

  .intro-visual {
    width: 100%;
    height: 100%;
  }

  .crosshair {
    width: 15px; 
    height: 15px;
  }

  .pvpMode {
    top: 2rem; 
    left: 1rem;
  }

  .health-bar {
    width: 80%; 
    bottom: 5%;
  }

  .bottom-left, .bottom-right {
    bottom: 45%; 
  }

  .open-dashboard-btn {
    bottom: 20px; 
    right: 20px;
  }

  .upper-right {
    display: none; 
  }

  .shoot-projectile-btn-left {
    top: 40%; 
    right: 20px;
  }

  .shoot-projectile-btn-right {
    top: 4%; 
    left: 20px;
  }
}




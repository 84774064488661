/* NamePicker Styles */
.name-picker {
  margin: 20px;
  padding: 10px;
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: #f0f0f0;
  border-radius: 10px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  width: 100%;
  flex: 1;
}

.name-label {
  font-size: 16px;
  margin-bottom: 10px;
  color: #333;
}

.name-input {
  width: 80%;
  max-width: 300px;
  padding: 10px;
  font-size: 16px;
  border: 2px solid #ccc;
  border-radius: 5px;
  box-shadow: inset 0 1px 3px rgba(0, 0, 0, 0.1);
}

.name-input:focus {
  border-color: #007bff;
  outline: none;
  box-shadow: 0 0 5px rgba(0, 123, 255, 0.5);
}
.name-picker-container {
  height: 100vh; /* Full viewport height */
  overflow-y: auto; /* Enable vertical scrolling */
  padding: 20px; /* Optional: Add some padding */
}
.name-picker {
  max-width: 600px; /* Optional: Set a max width for the form */
  margin: 0 auto; /* Center the form horizontally */
  background-color: #fff; /* Optional: Set a background color */
  padding: 20px; /* Optional: Add some padding */
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1); /* Optional: Add a box shadow */
  border-radius: 8px; /* Optional: Add rounded corners */
}



@import url('https://fonts.googleapis.com/css2?family=Orbitron:wght@400;700&display=swap');

body, html {
  margin: 0;
  padding: 0;
  height: 100%;
  font-family: 'Orbitron', sans-serif;
  background-color: #000;
  color: #fff;
  overflow: hidden;
  
}



/* Container for the entire login/signup component */
.login-container {
  background-image: url('../assets/login/loginImage.webp');
  background-size: cover;
  background-position: center;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  
  text-shadow: 
  -0.1px -0.1px 0 black,  
  0.1px -0.1px 0 black,
  -0.1px  0.1px 0 black,
  0.1px  0.1px 0 black;
}

/* Frame for the login/signup form */
.login-frame {
  background: rgba(20, 20, 40, 0.8);
  border-radius: 50px; /* Increased border-radius for rounded corners */
  width: 400px;
  padding: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

/* Content inside the login/signup frame */
.login-content {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
}

/* Title for the login/signup form */
.login-title {
  color: #89D3DF;
  font-size: 32px;
  margin-bottom: 20px;
}

/* Subtitle for additional information */
.login-subtitle {
  color: #89D3DF;
  font-size: 18px;
  margin-bottom: 30px;
}

/* Container for input fields */
.input-group {
  width: 100%;
  margin-bottom: 15px;
}

/* Input fields for email, password, and username */
.login-input {
  width: 100%;
  padding: 10px;
  background: transparent;
  border: none;
  border-bottom: 2px solid #89D3DF;
  color: #fff;
  margin-bottom: 10px;
}

/* Button for login/signup actions */
.login-button {
  width: 100%;
  padding: 10px;
  background: linear-gradient(45deg, #89D3DF, #8A2BE2);
  border: none;
  border-radius: 20px;
  color: #fff;
  font-weight: bold;
  cursor: pointer;
  margin-top: 20px;
}

/* Container for additional options (forgot password, create account, logout) */
.login-options {
  display: flex;
  justify-content: space-between;
  width: 100%;
  margin-top: 20px;
}

/* Links for forgot password, create account, and logout */
.forgot-password, .create-account, .logout {
  color: #89D3DF;
  text-decoration: none;
  font-size: 12px;
  cursor: pointer;
}

/* Hover effect for the links */
.forgot-password:hover, .create-account:hover, .logout:hover {
  text-decoration: underline;
}

/* Error message styling */
.error-message {
  color: #ff4d4d;
  margin-top: 10px;
  text-align: center;
}
